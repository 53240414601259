import styled from 'styled-components';
import InformationSolid from '@simplywallst/ui-core/icons/InformationSolid';

export const Info = styled(InformationSolid)`
  opacity: 0.5;
  > * {
    fill: ${({ theme }) => theme.color.white};
  }
`;

export const LabelWrapper = styled('svg')`
  overflow: visible;
`;
