import type { ReactElement } from 'react';
import { useState, useRef, useEffect } from 'react';
import { LabelWrapper, Info } from './styled';
import Tooltip from '@components/Tooltip';
import { useTheme } from '@simplywallst/ui-core';
import useWindowSize from '@/hooks/useWindowSize';

import { VisxText } from '@/components/VisxText';

interface Props {
  x: string;
  y: string;
  title: string;
  date: string;
  footer?: string;
  tooltip?: () => ReactElement[] | ReactElement;
  highlight?: boolean;
  textAnchor?: 'end' | 'start';
}

const Label = ({ x, y, title, date, tooltip, textAnchor = 'start' }: Props) => {
  const [iconX, setIconX] = useState(0);
  const [iconPadding, setIconPadding] = useState(0);
  const theme = useTheme();
  const { width: screenWidth } = useWindowSize();
  const labelRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const { current } = labelRef;
    if (screenWidth !== undefined && current) {
      const padding = screenWidth <= theme.mdMaxRaw ? -16 : -20;
      const width = current.getBBox().width;

      if (iconX !== width) {
        setIconX(width);
      }

      if (iconPadding !== padding) {
        setIconPadding(screenWidth <= theme.mdMaxRaw ? -16 : -20);
      }
    }
  }, [iconPadding, iconX, screenWidth, theme.mdMaxRaw]);

  return (
    <LabelWrapper x={x} y={y} shapeRendering="geometricPrecision">
      {tooltip && (
        <Tooltip title={tooltip()} placement="top">
          <g
            transform={`translate(${
              textAnchor === 'start' ? iconX : iconPadding
            } -2)`}
          >
            <Info />
            <rect width="24" height="24" fill="transparent" />
          </g>
        </Tooltip>
      )}
      <VisxText
        innerRef={labelRef}
        dx={textAnchor === 'end' && tooltip !== undefined ? iconPadding : 0}
        dy="4"
        textAnchor={textAnchor}
        verticalAnchor="start"
        fill={theme.color.whiteRGBA(0.5)}
        typography="xSmall"
      >
        {title}
      </VisxText>
      {date && (
        <VisxText
          dy="24"
          fill={theme.color.white}
          textAnchor={textAnchor}
          verticalAnchor="start"
          typography="xSmall"
        >
          {date}
        </VisxText>
      )}
    </LabelWrapper>
  );
};

export default Label;
