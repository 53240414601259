import { format, formatDistanceStrict } from 'date-fns';
import { utcFormat } from 'd3-time-format';
import { scaleTime } from '@visx/scale';
import { useTheme } from '@simplywallst/ui-core';
import Label from '../Label';
import BuyLimit from '../BuyLimit';
import { SvgWithOverflow, Circle, LabelLine, Line, Tooltip } from './styled';
import { useOverviewTranslation } from '@/pages/CompanyReport/components/OverviewSection/hooks/useOverviewTranslation';
import { useDateFnsLocale } from '@/hooks/useDateFnsLocale';

const dateFormat = 'MMM dd yyyy';
const formatUTC = utcFormat('%b %d %Y');
const RADIUS = 4.5;
//Prevent clipping
const MIN_PERCENT = 2;
const MAX_PERCENT = 98;
const MAX_PROGRESS = MAX_PERCENT - MIN_PERCENT;

enum LABEL_Y {
  TOP = 59,
  MIDDLE = 75,
  BOTTOM = 121,
}

interface ChartVariables {
  showBuyLabel: boolean;
  todayX: number;
  todayY: number;
  todayLabelY: number;
  todayShowDate: boolean;
  exDividendX: number;
  payDateX: number;
  payDateY: number;
  barProgressX: number;
  barProgressActive: boolean;
  barBackgroundStroked: boolean;
}

const getChartVariables = (inputs: {
  dividendNextDate: number;
  dividendNextPayDate: number;
  now: number;
}): ChartVariables | undefined => {
  const { dividendNextDate, dividendNextPayDate, now } = inputs;
  if (now < dividendNextDate) {
    //Before ex dividend
    const xScale = scaleTime({
      range: [0, 1],
      domain: [now, dividendNextPayDate],
    });
    const middleX =
      (xScale(dividendNextDate) as number) * MAX_PROGRESS + MIN_PERCENT;

    return {
      showBuyLabel: true,
      todayX: MIN_PERCENT,
      todayY: LABEL_Y.BOTTOM,
      todayLabelY: LABEL_Y.BOTTOM,
      todayShowDate: true,
      exDividendX: middleX,
      payDateX: MAX_PERCENT,
      payDateY: LABEL_Y.BOTTOM,
      barProgressX: middleX,
      barProgressActive: true,
      barBackgroundStroked: false,
    };
  } else if (now >= dividendNextDate && now < dividendNextPayDate) {
    //From ex dividend
    const xScale = scaleTime({
      range: [0, 1],
      domain: [dividendNextDate, dividendNextPayDate],
    });
    const middleX = (xScale(now) as number) * MAX_PROGRESS + MIN_PERCENT;

    return {
      showBuyLabel: false,
      todayX: middleX,
      todayY: LABEL_Y.MIDDLE,
      todayLabelY: LABEL_Y.MIDDLE,
      todayShowDate: true,
      exDividendX: MIN_PERCENT,
      payDateX: MAX_PERCENT,
      payDateY: LABEL_Y.BOTTOM,
      barProgressX: middleX,
      barProgressActive: false,
      barBackgroundStroked: false,
    };
  } else if (now >= dividendNextPayDate) {
    //After ex dividend
    const xScale = scaleTime({
      range: [0, 1],
      domain: [dividendNextDate, now],
    });
    const middleX =
      (xScale(dividendNextPayDate) as number) * MAX_PROGRESS + MIN_PERCENT;

    return {
      showBuyLabel: false,
      todayX: MAX_PERCENT,
      todayY: LABEL_Y.TOP,
      todayLabelY: LABEL_Y.TOP - 24,
      todayShowDate: false,
      exDividendX: MIN_PERCENT,
      payDateX: middleX,
      payDateY: LABEL_Y.MIDDLE,
      barProgressX: middleX,
      barProgressActive: false,
      barBackgroundStroked: true,
    };
  }
  //Else error with logic - dont render
  return undefined;
};

interface NextDividendProps {
  dividendNextDate: number;
  dividendNextPayDate: number;
}
const NextDividend = ({
  dividendNextDate,
  dividendNextPayDate,
}: NextDividendProps) => {
  const theme = useTheme();
  const now = Date.now();

  const variables = getChartVariables({
    dividendNextDate,
    dividendNextPayDate,
    now,
  });

  const t = useOverviewTranslation();
  const dateFnsLocale = useDateFnsLocale();
  if (!variables) return null;
  const {
    showBuyLabel,
    todayX,
    todayY,
    todayLabelY,
    todayShowDate,
    exDividendX,
    payDateY,
    payDateX,
    barProgressX,
    barProgressActive,
    barBackgroundStroked,
  } = variables;

  const progressBarSectionStart = theme.x5spacePx + 3;
  const progressBarY = progressBarSectionStart + theme.x3spacePx;

  return (
    <SvgWithOverflow height="168" width="100%" shapeRendering="crispEdges">
      {/* ex dividend label*/}
      <Label
        x={`${exDividendX}%`}
        y="12"
        title={t('fundamentals.dividend.chart.exDividend')}
        date={formatUTC(new Date(dividendNextDate))}
        textAnchor={exDividendX > 50 ? 'end' : 'start'}
        tooltip={() => (
          <Tooltip>
            {t('fundamentals.dividend.chart.exDividendTooltip')}
          </Tooltip>
        )}
      />
      {/* pay day label*/}
      <Label
        x={`${payDateX}%`}
        y={`${payDateY}`}
        title={t('fundamentals.dividend.chart.dividendPayDate')}
        date={formatUTC(new Date(dividendNextPayDate))}
        textAnchor={payDateX > 50 ? 'end' : 'start'}
      />
      {/* today label*/}
      <Label
        x={`${todayX}%`}
        y={`${todayLabelY}`}
        title={t('fundamentals.dividend.chart.today')}
        textAnchor={todayX > 50 ? 'end' : 'start'}
        date={todayShowDate ? format(now, dateFormat) : ''}
      />

      {/* ex dividend label line */}
      <LabelLine
        x1={`${exDividendX}%`}
        x2={`${exDividendX}%`}
        y1={progressBarY}
        y2={LABEL_Y.TOP}
      />
      {/* pay date label line */}
      <LabelLine
        x1={`${payDateX}%`}
        x2={`${payDateX}%`}
        y1={progressBarY}
        y2={payDateY}
      />
      {/* today label line */}
      <LabelLine
        x1={`${todayX}%`}
        x2={`${todayX}%`}
        y1={progressBarY}
        y2={todayY}
      />

      {/* progress line section */}
      <svg
        y={progressBarSectionStart}
        height={theme.x15spacePx}
        width="100%"
        shapeRendering="crispEdges"
      >
        {showBuyLabel && dateFnsLocale.status === 'success' && (
          <BuyLimit
            title={t('fundamentals.dividend.buyLimit', {
              day: formatDistanceStrict(dividendNextDate, now, {
                unit: 'day',
                locale: dateFnsLocale.locale,
              }),
            })}
          />
        )}
        {/* background line */}
        <Line
          x1={`${MIN_PERCENT}%`}
          y1={theme.x3spacePx}
          x2={`${MAX_PERCENT}%`}
          y2={theme.x3spacePx}
          stroked={barBackgroundStroked}
        />
        {/* progress line */}
        <Line
          x1={`${MIN_PERCENT}%`}
          y1={theme.x3spacePx}
          x2={`${barProgressX}%`}
          y2={theme.x3spacePx}
          active={barProgressActive}
        />
        {/* ex dividend dot */}
        <Circle cx={`${exDividendX}%`} cy={theme.x3spacePx} r={RADIUS} />
        {/* pay date dot */}
        <Circle cx={`${payDateX}%`} cy={theme.x3spacePx} r={RADIUS} />
        {/* today dot */}
        <Circle cx={`${todayX}%`} cy={theme.x3spacePx} r={RADIUS} active />
      </svg>
    </SvgWithOverflow>
  );
};

export default NextDividend;
