import { useTheme } from '@simplywallst/ui-core';
import { VisxText } from '@/components/VisxText';

interface Props {
  title: string;
}

const BuyLimit = ({ title }: Props) => {
  const theme = useTheme();
  return (
    <VisxText
      y={theme.x8spacePx - 36}
      dy={theme.x3spacePx}
      dx={theme.x2spacePx + 4}
      verticalAnchor="middle"
      width={160}
      lineHeight="1.5em"
      typography="xSmallStrong"
      fill={theme.color.chart01}
    >
      {title}
    </VisxText>
  );
};

export default BuyLimit;
