import styled, { css } from 'styled-components';

export const Tooltip = styled('div')`
  max-width: 200px;
`;

interface LineProps {
  stroked?: boolean;
  active?: boolean;
}
export const Line = styled('line')<LineProps>`
  stroke: ${({ theme, active }) =>
    active ? theme.color.chart01 : theme.color.darkerGray};
  stroke-width: 4;

  ${({ stroked }) =>
    stroked &&
    css`
      stroke-dasharray: 8 4;
    `}
`;

export const LabelLine = styled('line')`
  stroke: ${({ theme }) => theme.color.darkGray};
  stroke-width: 1;
`;

interface CircleProps {
  active?: boolean;
}
export const Circle = styled('circle')<CircleProps>`
  fill: ${({ theme, active }) =>
    active ? theme.color.chart01 : theme.color.darkGray};
`;

export const SvgWithOverflow = styled('svg')`
  margin: 0px -2%;
`;
